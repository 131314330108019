<template>
  <div class="result-page">
    <div v-if="exam_record" class="result-main page-main">
      <div class="result flex">
        <div
          class="result-tip wrong"
          v-if="exam_record.record_exam_status == -1"
        >
          <div class="text-center">
            <close-circle-outlined class="icon" />
          </div>
          <div class="text-center">很遗憾，您未通过本次考试</div>
        </div>
        <div
          class="result-tip right"
          v-if="exam_record.record_exam_status == 1"
        >
          <div class="text-center">
            <check-circle-outlined class="icon" />
          </div>
          <div class="text-center">恭喜您完成本次考试</div>
        </div>
        <div class="user-info">
          <div class="exam-title">{{ exam_record.exam_name }}</div>
          <div class="score">
            {{ exam_record.record_exam_score }}<text>分</text>
          </div>
          <div>
            本次考试用时：{{ getTimeFormat(exam_record.record_exam_duration) }}
          </div>
        </div>
        <div>
          <a-space>
            <a-button @click="resetExam">考试列表</a-button>
            <a-button @click="goBack" type="primary">返回首页</a-button>
          </a-space>
        </div>
      </div>
      <div class="title-icon">答卷</div>
      <div class="yxzx-divider"></div>
      <div
        class="box bg_white"
        v-for="(item, index) in exam_question_record"
        :key="item.question_record_id"
      >
        <div class="question_number">
          {{ index + 1 }}、<text
            >【{{
              item.question_type_id == 1
                ? "单选题"
                : item.question_type_id == 2
                ? "多选题"
                : "判断题"
            }}】</text
          >
        </div>
        <div class="flex question_title">
          <div v-html="item.question_title || '-'"></div>
        </div>
        <div class="option_box">
          <!-- 单选、判断 -->
          <template
            v-if="item.question_type_id == 1 || item.question_type_id == 3"
          >
            <template
              v-for="(item2, index2) in [1, 2, 3, 4, 5, 6]"
              :key="item2"
            >
              <div
                v-if="item[`question_option_${item2}`]"
                :class="
                  item.question_option_right == option[index2]
                    ? 'right option-item'
                    : option[index2] == item.question_record_answer
                    ? item.question_record_answer == item.question_option_right
                      ? 'right option-item'
                      : 'wrong option-item'
                    : 'option-item'
                "
              >
                {{ option[index2] }}、{{ item[`question_option_${item2}`] }}
              </div>
            </template>
          </template>
          <!-- 多选 -->
          <template v-if="item.question_type_id == 2">
            <template
              v-for="(item2, index2) in [1, 2, 3, 4, 5, 6]"
              :key="item2"
            >
              <div
                v-if="item[`question_option_${item2}`]"
                :class="{
                  'option-item': true,
                  right:
                    item.question_option_right.indexOf(option[index2]) > -1,
                  wrong:
                    item.question_option_right.indexOf(option[index2]) == -1 &&
                    item.question_record_answer.indexOf(option[index2]) > -1,
                }"
              >
                {{ option[index2] }}、{{ item[`question_option_${item2}`] }}
              </div>
            </template>
          </template>
        </div>
        <a-divider></a-divider>
        <div class="answer">
          <div>
            正确答案：<text class="right">{{
              item.question_option_right
            }}</text>
          </div>
          <div>
            您的答案：<text
              :class="
                item.question_record_answer == item.question_option_right
                  ? 'right'
                  : 'wrong'
              "
              >{{ item.question_record_answer }}</text
            >
          </div>
        </div>
        <a-divider></a-divider>
        <div class="analysis">
          <div class="analysis_title">【答案解析】</div>
          <div class="question_analysis">
            <div v-html="item.question_analysis || '-'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
// import moment from "moment";
import url from "../../utils/url.js";
import { getExamRecordData } from "@/api/main.js";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { getUserData } from "@/utils/storeData.js";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  name: "Result",
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      record_id: null,
      exam_record: null,
      user: computed(() => getUserData()).value,
      exam_question_record: [],
      option: ["A", "B", "C", "D", "E", "F"],
    });

    const getTimeFormat = (_time) => {
      let time = "";
      let hours = _time > 3600 ? Math.floor(_time / 3600) : 0;
      let minutes = _time > 60 ? Math.floor((_time % 3600) / 60) : 0;
      let second = (_time % 3600) % 60;
      time = `${hours.toString().padStart(2, 0)}时${minutes
        .toString()
        .padStart(2, 0)}分${second.toString().padStart(2, 0)}秒`;
      return time;
    };

    //取交集
    const getIntersection = (arr1, arr2) => {
      console.log("arr1, arr2", arr1, arr2);
      let intersection = null;
      if (arr2) {
        intersection = arr1.filter(function (val) {
          return arr2.indexOf(val) > -1;
        });
      }
      return intersection;
    };

    const goBack = () => {
      url.replaceTo("/index");
    };
    const resetExam = () => {
      url.replaceTo("/exam/index");
    };

    const getExamRecordDataFun = () => {
      getExamRecordData({
        record_id: pageData.record_id,
      })
        .then((res) => {
          if (res.code == 200) {
            console.log("res", res);
            pageData.exam_record = res.data.exam_record;
            pageData.exam_question_record = res.data.exam_question_record;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    //取差集
    const getDiff = (arr1, arr2) => {
      console.log("arr1, arr2", arr1, arr2);
      let diff = null;
      diff =
        arr1 &&
        arr2 &&
        arr1.filter(function (val) {
          return arr2.indexOf(val) === -1;
        });
      return diff;
    };
    const toArr = (str) => {
      if (str && str.length > 0) {
        return str.split(",");
      } else {
        return [];
      }
    };
    const isExsit = (option, temp) => {
      console.log("option, temp", option, temp);
      if (temp && temp.indexOf(option) != -1) {
        return true;
      } else {
        return false;
      }
    };

    if (route?.query?.recordId) {
      pageData.record_id = route?.query?.recordId;
      getExamRecordDataFun();
    } else {
      message.error("暂无考试记录");
    }

    const getRight = (item, item2) => {
      console.log("item", item, item2);
      console.log("item", item);
      item.question_record_answer.indexOf(`question_option_${item2}`) > -1;
    };

    return {
      ...toRefs(pageData),
      getTimeFormat,
      goBack,
      resetExam,
      getDiff,
      toArr,
      getIntersection,
      isExsit,
      getRight,
    };
  },
});
</script>

<style lang="less" scoped>
.result-page {
  height: 100%;
  .result-main {
    padding: 30px 0 50px;
  }
}
.container {
  padding: 0 35px;
}

.result {
  width: 100%;
  height: 300px;
  background: #fff;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: center;
  margin-bottom: 40px;
  border-radius: 8px;
  position: relative;
  .result-tip {
    width: 200px;
    .icon {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
  .avatar {
    width: 30px;
    border-radius: 50%;
    margin-right: 12px;
    margin-bottom: 30px;
  }
  .user_name {
    // padding-top: 90px;
    color: #333333;
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
  }
  .user-info {
    margin-left: 100px;
    margin-right: 100px;
    text-align: left;

    .exam-title {
      font-size: 18px;
      margin-bottom: 12px;
    }
    .result_text {
      color: #969696;
      font-size: 14px;
      margin-bottom: 12px;
    }

    .score {
      color: #2384e2;
      font-size: 30px;
      margin-bottom: 12px;

      text {
        font-size: 14px;
      }
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: #f2f2f2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: -20px;
  }
  &::after {
    left: unset;
    right: -20px;
  }
}

.label {
  color: #333333;
  font-size: 14px;
}

.value {
  color: #333333;
  font-size: 16px;
}

.bg_white {
  background-color: #fff;
}

.mb_20 {
  margin-bottom: 20px;
}

.btn {
  width: 311px;
  height: 80px;
  line-height: 80px;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
}

.btn-reset {
  background-color: #ffffff;
  color: #969696;
  border: solid 1px #969696;
}

.btn-back {
  background-color: #1e83e5;
  color: #ffffff;
}

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-radius {
  border-radius: 10px;
}

.box {
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.question_title {
  color: #333333;
  font-size: 16px;

  text {
    color: #969696;
  }
}

.question_number {
  font-size: 14px;
  margin-bottom: 10px;

  text {
    color: #969696;
  }
}

.option_box {
  margin-top: 24px;
  font-size: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .option-item {
    width: 49%;
    margin-bottom: 12px;
  }
}

.right {
  color: #20c5a8;
}

.wrong {
  color: #bb2f2f;
}

.answer {
  //   margin: 12px 0;
  font-size: 14px;
  line-height: 28px;
  color: #333333;
}

.analysis {
  //   padding: 12px 0;
  font-size: 14px;
  color: #333333;

  .analysis_title {
    color: #969696;
    margin-bottom: 12px;
  }
}
.title-icon {
  position: relative;
  padding-left: 20px;
  font-size: 18px;
}
.title-icon::after {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1890ff;
}
</style>
